
$(".tabJsBtn").click(function (e) {
    if(!$(this).hasClass('active')){
        $(this).parents('.tabJsWr').find('.tabJsBtn.active').removeClass('active');
        $(this).parents('.tabJsWr').find('.tabJsBlock.active').removeClass('active');
        $(this).addClass('active');
        var id = $(this).attr('data-open');
        $('[data-block="' + id + '"]').addClass('active')
    }
})


$(".tabJsWr").each(function () {
    $(this).find('.tabJsBtn').first().addClass('active');
    $(this).find('.tabJsBlock').first().addClass('active');
});