// open burger menu
let listItems = $('.burger-menu__nav.main-nav').find(".nav-menu-list__item");
$('.js_burger_btn').on('click', function() {
  if (!$(this).hasClass('active')) {
    $(this).addClass('active');
    $('.burger-menu').addClass('active');
    $('.header-new').addClass('active');
    $('.main-nav').show()
    listItems.each(function (i, item) {
      setTimeout(function () {
        $(item).addClass('show');
      }, 50 * i)
    });
    $('.nav-menu-list__link').each(function () {
      if($(this).hasClass('open') || $(this).hasClass('active-link')) {
        $(this).trigger('click');
      }
    });
  } else {
    $(this).removeClass('active');
    $('.burger-menu').removeClass('active');
    $('.header-new').removeClass('active');
    $('.js_open_burger').removeClass('active');
    $('[data-open="design"]').removeClass('open');
    clickCount = 0;
    listItems.each(function (i, item) {
      setTimeout(function () {
        $(item).removeClass('show');
      }, 50 * i)
    });
    $('.js_open_menu').removeClass('active')
    $('.main-nav').hide().removeClass('js_first js_second active')
    $('.submenu').hide().removeClass('js_first js_second js_back active')
    $('.submenu__line').animate({
      height: "0"
    }, 3000);

    $('[data-open="store"]').removeClass('disabled')
  }
});
$('.js_open_burger').on('click', function () {
  if (!$(this).hasClass('active')) {
    $(this).addClass('active');
    $('[data-open="design"]').addClass('open');
  }
  $('.js_burger_btn').trigger('click');
});
// open burger menu

// catalog menu open
$(window).on("load resize",function(e){
  if($(window).width() < 993) {
    $('.js_back_menu').fadeIn().addClass('nav-menu-list__item show')
  }
  if($(window).width() > 993) {
    $('.js_back_menu').fadeOut().removeClass('nav-menu-list__item show')
  }
  if($('.submenu.second').hasClass('active')) {
    $('.main-nav').addClass('js_second')
  } else {
    $('.main-nav').removeClass('js_second')
    $('.submenu.second').find('.submenu__line').fadeOut(500).css('height', "0");
  }
});
$('.js_open_menu').on('click', function (e) {
  e.stopPropagation();
  e.preventDefault();
  let itemId = $(this).attr('data-open');
  let listItems = $(`[data-id='${itemId}']`).find(".nav-menu-list__item");
  let parentItem = $(`[data-id='${itemId}']`).closest(".submenu");
  let secondItems = $('.second').find(".nav-menu-list__item");
  let submenuItems = $('.submenu__inner').find(".nav-menu-list__item");

  if($(window).width() > 992) {
    if($(this).closest('.burger-menu__nav').hasClass('main-nav')) {
      $('.main-nav').addClass('js_first')
    } else {
      function translateItems(callback) {
        $('.main-nav').addClass('js_second')
        callback();
      }

      translateItems(function () {
        $('.first').addClass('js_second')
      });
    }
  }

  if($(window).width() < 993) {
    $('.main-nav').addClass('js_first')
    if($(this).closest('.submenu').hasClass('first')) {
      $('.first').addClass('js_second')
    }
  }

  if (!$(this).hasClass('active')) {
    if($(this).hasClass('nav-menu-list__link--catalog')) {
      $('.second').find('.submenu__inner').fadeOut().removeClass('active');
      $('.second').find('.submenu__inner').find('.submenu__line').fadeOut(500).css('height', "0");
    } else {
      $('.submenu__inner').fadeOut().removeClass('active');
      $('.submenu__inner').find('.submenu__line').fadeOut(500).css('height', "0");
    }

    $(this).closest('.nav-menu-list').find('.js_open_menu').removeClass('active')

    if($(this).hasClass('nav-menu-list__link--catalog')) {
      secondItems.each(function (i, item) {
        $(item).removeClass('show');
      });
    } else {
      submenuItems.each(function (i, item) {
        $(item).removeClass('show');
      });
    }

    $(this).addClass('active')

    setTimeout(function () {
      if($('.first').hasClass('js_back')) {
        $('.first').removeClass('js_back')
      }
      if($('.second').hasClass('js_back')) {
        $('.second').removeClass('js_back')
      }

      $(parentItem).fadeIn().addClass('active')
      $(`[data-id='${itemId}']`).fadeIn().addClass('active')
      listItems.each(function (i, item) {
        setTimeout(function () {
          $(item).addClass('show');
        }, 100 * i)
      });
    }, 600);

    setTimeout(function () {
      $(`[data-id='${itemId}']`).find('.submenu__line').fadeIn().animate({
        height: "100%"
      }, 1200);
    }, 1000)
  } else {
    $(this).removeClass('active')
    $(parentItem).fadeOut(500).removeClass('active')
    $(`[data-id='${itemId}']`).fadeOut(500).removeClass('active')
    listItems.each(function (i, item) {
      setTimeout(function () {
        $(item).removeClass('show');
      }, 100 * i)
    });
    $(`[data-id='${itemId}']`).find('.submenu__line').fadeOut(500).css('height', "0");
  }
});

$('.js_back_menu').on('click', function () {
  let submenuFirstItems = $('.first').find(".nav-menu-list__item");
  let submenuSecondItems = $('.second').find(".nav-menu-list__item");

  if($(this).closest('.submenu').hasClass('second')) {
    $('.second').removeClass('active').addClass('js_back')
    submenuSecondItems.each(function (i, item) {
      $(item).removeClass('show');
    });

    $('.first').removeClass('js_second').addClass('js_first active')
    submenuFirstItems.each(function (i, item) {
      $(item).addClass('show');
    });
    $('.first').find('.submenu__line').fadeIn().css('height', "100%");
    $('.first').find('.js_open_menu').removeClass('active')
    $('.main-nav').removeClass('js_second')
  } else if($(this).closest('.submenu').hasClass('first')) {
    $('.first').removeClass('active').addClass('js_back')
    submenuFirstItems.each(function (i, item) {
      $(item).removeClass('show');
    });
    $('.submenu__line').fadeOut(500);
    $('.main-nav').removeClass('js_first')
    $('.main-nav').find('.js_open_menu').removeClass('active')
  }
});
// catalog menu open
