// $('.scrollJs').click(function () {
//     var scroll_el = $(this).attr('data-scroll');
//     if(!$(this).hasClass('active')){
//         $(this).parents('.scrollJsWr').find('.scrollJs.active').removeClass('active');
//         $(this).addClass('active');
//     }
//     $('html, body').animate({
//         scrollTop: $("#" + scroll_el).offset().top - $('.header__bottom').height()
//         // scrollTop: $("#" + scroll_el).offset().top 
//     }, 500);
// });
