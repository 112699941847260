import Inputmask from "inputmask";
import Validate from "jquery-validation";

var locale = $('html').attr('lang');
if (locale !== "en") require('jquery-validation/dist/localization/messages_' + locale + '.min.js');

Inputmask({
    mask: '+7 (999) 999-9999',
    clearMaskOnLostFocus: true,
    clearIncomplete: true,
    showMaskOnHover: false,
}).mask('input[type=tel]');

$("form").on("submit", function (evt) {
    if($(this).hasClass('search__form')) return
    evt.stopPropagation();
    evt.preventDefault();
});

$("[data-js='callback']").validate({
    submitHandler: function (form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $(form).serialize();
        $(form)[0].reset();
        moveLabels($(form));
        $(form).find(".form__bnt-wr").hide();
        $(form).find(".form__loader").show();
        $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token": csrf_token,
                "data": formdata,
                "subj": "callback"
            },
            success: function (data) {
                $(form).find(".form__loader").hide();
                $(form).find(".form__success").slideDown(1000);
                setTimeout(function () {
                    $(form).find(".form__success").slideUp(1000);
                    $(form).find(".form__bnt-wr").show();
                }, 5000);
                moveLabels($(form))
                setTimeout(function () {
                    $.magnificPopup.close();
                }, 4000);
            }
        })
        return false;
    }
});
$(".search__form").validate();

$("[data-js='feedback']").validate({
    submitHandler: function (form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $(form).serialize();
        $(form)[0].reset();
        $(form).find(".form__bnt-wr").hide();
        $(form).find(".form__loader").show();
        $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token": csrf_token,
                "data": formdata,
                "subj": "feedback"
            },
            success: function (data) {
                $(form).find(".form__loader").hide();
                $(form).find(".form__success").slideDown(1000);
                setTimeout(function () {
                    $(form).find(".form__success").slideUp(1000);
                }, 5000);
                setTimeout(function () {
                    $(form).find(".form__bnt-wr").show();
                }, 6000);
                moveLabels($(form))
            }
        })
        return false;
    }
});


$("[data-js='ask_specialist']").validate({
    submitHandler: function (form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $(form).serialize();
        $(form)[0].reset();
        moveLabels($(form));
        $(form).find(".form__bnt-wr").hide();
        $(form).find(".form__loader").show();
        $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token": csrf_token,
                "data": formdata,
                "subj": "ask_specialist"
            },
            success: function (data) {
                $(form).find(".form__loader").hide();
                $(form).find(".form__success").slideDown(1000);
                setTimeout(function () {
                    $(form).find(".form__success").slideUp(1000);
                    $(form).find(".form__bnt-wr").show();
                }, 5000);
                moveLabels($(form))
                setTimeout(function () {
                    $.magnificPopup.close();
                }, 4000);
            }
        })
        return false;
    }
});




$("[data-js='question']").validate({
    submitHandler: function (form) {
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        var formdata = $(form).serialize();
        $(form)[0].reset();
        moveLabels($(form));
        $(form).find(".form__bnt-wr").hide();
        $(form).find(".form__loader").show();
        $.ajax({
            url: routes.postSend,
            type: 'POST',
            data: {
                "_token": csrf_token,
                "data": formdata,
                "subj": "question"
            },
            success: function (data) {
                $(form).find(".form__loader").hide();
                $(form).find(".form__success").slideDown(1000);
                setTimeout(function () {
                    $(form).find(".form__success").slideUp(1000);
                    $(form).find(".form__bnt-wr").show();
                }, 5000);
                moveLabels($(form))
                setTimeout(function () {
                    $.magnificPopup.close();
                }, 4000);
            }
        })
        return false;
    }
});


function moveLabels(form){
    form.find('.form__label-top').each(function(){
        $(this).removeClass('active');
    })
}

//span label to top ifnot empty
function placeholderInput() {
    $(".form__input")
        .on("focus", function () {
            $(this).siblings().addClass("active");
            $(this).addClass('active-input');
        })
        .on("blur", function () {
            if ($(this).val() == "") {
                $(this).siblings().removeClass("active");
                $(this).removeClass('active-input');
            }
        });

    // span to top if info added from admin
    // setTimeout(function () {
    //     $(".form__input").each(function () {
    //         if ($(this).val() != '') {
    //             $(this).siblings().addClass("active");
    //             $(this).addClass('active-input');
    //         }
    //     });
    // }, 100);
}

placeholderInput();


//dont send form with not full number
jQuery('[type="tel"]').each(function() {
    jQuery(this).keypress(function(e, a) {
      if ((e.which == 13) || (e.keyCode == 13)) {
        jQuery(this).blur();
      }
      return true;
    });
  });