
var adminImg = document.querySelectorAll(".admin__content");
if (adminImg) {
    // a bit deeper browser support compare with forEach method
    Array.prototype.forEach.call(adminImg, function (wrapper, i) {
        var images = wrapper.querySelectorAll("img");
        if (images) {
            Array.prototype.forEach.call(images, function (img, i) {
                const float = img.style.float;
                console.log(float);
                if (float == "left") img.classList.add("margin-left-none");
                else if (float == "right") img.classList.add("margin-right-none");
            });
        }
    });
};

if ($(window).width() < 480) {
    Array.prototype.forEach.call(adminImg, function (wrapper, i) {
        var images = wrapper.querySelectorAll("img");
        console.log(images.length);
        if (images.length > 0) {
            Array.prototype.forEach.call(images, function (img, i) {
                img.style.float = 'none';
                img.style.margin = '0 0 15px';
                img.classList.add("margin-left-none");
                img.classList.add("margin-right-none");
            });
        }
    });
}

$(".admin__content").find("table").each(function () {
    $(this).wrap("<div class='admin__table-wr'></div>");
});

$(".admin__content").find("iframe").each(function () {
    $(this).wrap("<div class='admin__video'></div>");
    // $(this).closest('p').addClass('video__item');
});



