$('.spoiler-title').on('click',function(){
    if($(this).parents('.spoiler').hasClass('active')){
        $(this).parents('.spoiler').find(".spoiler-content").slideUp(300);
        $(this).parents('.spoiler').removeClass("active");
    }else{
        // $(this).parents('.accordion__list').find(".accordion__item-content").slideUp(300);
        // $(this).parents('.accordion__list').find(".accordion__item.active").removeClass("active");
        $(this).parents('.spoiler').addClass("active");
        $(this).parents('.spoiler').find(".spoiler-content").slideDown(300);
    }
})
