if($('.modal-magnific').length > 0){

  $('.modal-magnific').magnificPopup({
      fixedContentPos: true,
      removalDelay: 300,
      mainClass: 'mfp-right'
  });
}


if($('.modal-magnific-full').length > 0){
  $('.modal-magnific-full').magnificPopup({
      fixedContentPos: true,
      removalDelay: 300,
      mainClass: 'mfp-full mfp-search',
      focus: '#input_search',
  });
}


$('.modal-magnific-nav').magnificPopup({
  fixedContentPos: true,
  removalDelay: 300,
  mainClass: 'mfp-full mfp-nav',
  callbacks: {
    open: function() {
      $('.js_burger_btn').trigger('click');
    },
    beforeClose: function() {
      $('.js_burger_btn').trigger('click');
    },
  }
});

if($('.modal-magnific-top').length > 0){
  $('.modal-magnific-top').magnificPopup({
    fixedContentPos: true,
    removalDelay: 300,
    mainClass: 'mfp-top',
    callbacks: {
      open: function() {
        if(!$('body').hasClass('overflow-hidden'))  $('body').addClass('overflow-hidden');
        $('body').addClass('modal-dec');
      },
      close: function() {
        $('body').removeClass('modal-dec');
        if(!$('.nav__btn').hasClass('opened'))  $('body').removeClass('overflow-hidden');
      }
    }
  });
}

$('.modal-magnific-remove-dec').on('click',function(){
  $('body').removeClass('modal-dec');
});


if($('.js-iframe').length > 0){
  $('.js-iframe').magnificPopup({
    type:'iframe',
  });
}

