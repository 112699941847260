$('.accordion__item-btn').on('click',function(){
    if($(this).parents('.accordion__item').hasClass('active')){
        $(this).parents('.accordion__item').find(".accordion__item-content").slideUp(300);
        $(this).parents('.accordion__item').removeClass("active");
    }else{
        // $(this).parents('.accordion__list').find(".accordion__item-content").slideUp(300);
        // $(this).parents('.accordion__list').find(".accordion__item.active").removeClass("active");
        $(this).parents('.accordion__item').addClass("active");
        $(this).parents('.accordion__item').find(".accordion__item-content").slideDown(300);
    }
})

// $('.accordion__item-btn').on('click',function(){
//     if($(this).parents('.accordion__item').hasClass('active')){
//         $(this).parents('.accordion__item').find(".accordion__item-content").slideUp(300);
//         $(this).parents('.accordion__item').removeClass("active");
//     }else{
//         $(this).parents('.accordion__list').find(".accordion__item-content").slideUp(300);
//         $(this).parents('.accordion__list').find(".accordion__item.active").removeClass("active");
//         $(this).parents('.accordion__item').addClass("active");
//         $(this).parents('.accordion__item').find(".accordion__item-content").slideDown(300);
//     }
// })
