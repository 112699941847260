import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'lazysizes';
import Slick from "slick-carousel";
import magnificPopup from "magnific-popup";
import Selectric from "selectric";
window.typeahead = require('bootstrap-typeahead');
// require('malihu-custom-scrollbar-plugin');
import Validate from "jquery-validation";
import Inputmask from "inputmask";

//cabinet
require("bootstrap");
require('x-editable/dist/bootstrap3-editable/js/bootstrap-editable');
require('bootstrap-switch');
// require('pgenerator');
//cabinet

$(function () {
    require('./modules/sticky');
    require('./modules/nav-menu');
    require('./modules/forms-common');
    require('./modules/admin');
    require('./modules/magnific');
    require('./modules/tabs');
    require('./modules/galleries');
    require('./modules/scroll');
    require('./modules/accordion');
    require('./modules/spoiler');

    $(".js-lastcrumb").click(function (e) {
        e.stopPropagation();
        e.preventDefault();
    })

    $(".interiorSliderJS").each(function () {
        var prev = $(this).parents('.gallery').find('.gallery__prev'),
            next = $(this).parents('.gallery').find('.gallery__next');
        $(this).slick({
            arrows: true,
            prevArrow: prev,
            nextArrow: next,
            infinite: false,
            slidesToShow: 1.5,
            slidesToScroll: 1.5,
            pauseOnHover: true,
            draggable: true,
            swipe: true,
            rows: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1.1,
                        slidesToScroll: 1.1,
                        arrows: false,
                    }
                },
            ]
        });
    });

    $("[data-js='selectric']").each(function () {
        $(this).selectric({
            disableOnMobile: false,
            nativeOnMobile: false
        });
    });

    var allShowed;
    $('.selectricRecommendJs').selectric({
        disableOnMobile: false,
        nativeOnMobile: false,
        onChange: function () {
            if (!allShowed) {
                allShowed = true;
                $(".recommend .selectric__all").show();
            }
        },
    });

    $(".jsRecommendFilter").click(function (e) {
        var selectValue = $(".selectricRecommendJs").val();
        if (selectValue == "all" || selectValue == "default") {
            $('.recommend__item').show();
        } else {
            $('.recommend__item').hide();
            $('.recommend__item-' + selectValue).show();
        }
    })
})


$(document).ready(function() {
    var modalShown = localStorage.getItem('modalShown');
    if (!modalShown) {
      $('#modal_cooki').show();
      $('#close_cookie_modal').click(function() {
        localStorage.setItem('modalShown', 'true');
        $('#modal_cooki').hide();
      });
    }
  });