$(document).ready(function() {
    // sticky
    if ($(".header__bottom").length) {
        var sticky = $(".header__bottom").offset().top;
        $(window).on("scroll load", addStickyHeader);

        function addStickyHeader() {
            if (window.pageYOffset > sticky) {
                $('header').addClass("header--sticky");
            } else {
                $('header').removeClass("header--sticky");
            }
        }
    }
})




