$(".gallerySliderJs").each(function () {
    var prev = $(this).parents('.gallery').find('.slick__prev'),
        next = $(this).parents('.gallery').find('.slick__next');
    $(this).slick({
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        pauseOnHover: true,
        draggable: true,
        prevArrow: prev,
        nextArrow: next,
        swipe: true,
        rows: 0,
        responsive:[
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 556,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1.1,
                }
            },
        ]
    });
});

$(".reviewsSliderJs").each(function () {
    $(this).slick({
        arrows: false,
        infinite: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        draggable: true,
        swipe: true,
        rows: 0,
        // autoplay: true,
        // autoplaySpeed: 5000,
        adaptiveHeight: true,
        speed: 500,
        fade: true,
        cssEase: 'linear'
    });
});

if ($('.equipmentSliderJs').length) {
    $(".equipmentSliderJs").each(function () {
        var prev = $(this).parents('.gallery').find('.slick__prev'),
            next = $(this).parents('.gallery').find('.slick__next');
        $(this).slick({
            arrows: true,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            pauseOnHover: true,
            draggable: true,
            prevArrow: prev,
            nextArrow: next,
            swipe: true,
            rows: 0,
            responsive:[
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 556,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        });
    });
}

if ($('.magnific-galleries').length) {
    $('.magnific-galleries').each(function () {
        $(this).magnificPopup({
            closeBtnInside: false,
            closeOnContentClick: true,
            delegate: 'a',
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    });
}

if ($('.magnific-gallery').length) {
    //magnific gallery more than one on page
    $('.magnific-gallery').magnificPopup({
        closeBtnInside: false,
        closeOnContentClick: true,
        type: 'image',
        delegate: 'a:not(.slick-cloned)',
        gallery: {
            enabled: true
        }
    });
}